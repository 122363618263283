.app-product-slider {
    position: relative;
    display: flex;
    align-items: center;
    height: fit-content;

    &__skeleton {
        width: 100%;
        aspect-ratio: 1;
    }

    &__slide-left__button, &__slide-right__button {
        width: 50px;
        height: 50px;
        opacity: 0;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: 0.3s;
        display: flex;
    }

    &__slide-left__button__arrow-img {
        fill: var(--main-bg-color);
    }

    &__slide-left__button {
        transform: rotate(90deg);
    }

    &__slide-right__button {
        transform: rotate(-90deg);
        right: 0;
    }

    &__image {
        width: 100%;
        object-fit: cover;
        transition: 0.3s;
    }

    &__pagination {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        align-items: center;
        grid-column-gap: 5px;
        box-sizing: border-box;
        z-index: 10000;

        &-button {
            flex-shrink: 0;
            width: 10px;
            height: 10px;
            aspect-ratio: 1;
            border-radius: 50%;
            border: none;
            background-color: var(--main-bg-color);
            padding: 0;

            &.active {
                background-color: var(--main-active-button-color);
            }
        }
    }

    &__image__loader-container {
        position: absolute;
        inset: 0;
        z-index: 10000;
        width: 100%;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (hover:hover) {
        &:hover &__slide-left__button,
        &:hover &__slide-right__button {
            opacity: 1;
            cursor: pointer;
        }
    }
}
