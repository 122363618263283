.auth-modal {

    .modal__body {
        padding: 50px 30px;
        margin: 20px;
        max-width: 850px;
        width: 100%;
    }

    &__container-phone {
        max-width: 326px;
    }

    &__content {
        display: flex;
        grid-column-gap: 10px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row-gap: 30px;
        width: 100%;
    }

    &__img {

        svg {
            width: 490px;
            height: 342px;
        }
    }

    .app-button {
        height: 50px;
        width: 100%;
        margin-top: 30px;
        max-width: 326px;
    }

    &__title {
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
        color: var(--main-font-color);
        margin: 0;
    }

    &__policy {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--main-border-color);
        margin: 10px 0 0 0;
        text-align: center;

        &-link {
            text-decoration: none;
            color: var(--main-font-color);
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        grid-row-gap: 5px;
        margin-top: 10px;
    }

    &__repeat-code, &__change-phone {
        text-decoration: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: var(--main-border-color);
        width: fit-content;
        text-align: left;

        &:hover(&:not(:disabled)) {
            color: var(--main-active-button-color);
        }

        &:focus-visible {
            border-radius: 4px;
            outline: var(--border) var(--input-active-color);
        }

        &:disabled {
            cursor: default;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        max-width: 252px;
        color: var(--main-border-color);
        margin: 0 0 10px;
    }

    .app-button:focus-visible {
        outline: var(--border) var(--input-active-color);
    }

    @media (max-width: 1000px) {

        .modal__body {
            width: auto;
            margin: 0;
        }

        &__content {
            justify-content: center;
            padding: 10px;
        }

        &__wrapper {
            max-width: 326px;
        }

        &__img {
            display: none;
        }
    }

    @media (max-width: 768px) {
        .modal__body {
            border-radius: 15px;
        }
    }
}

.chevron {
    display: none;
}

.swipe-block {
    display: none;
}
