.products-menu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.products__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
}
