.modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-overlay-color);
    opacity: 0;
    transition: all .3s ease-in-out;
    cursor: pointer;
    z-index: 100;

    &.show {
        opacity: 1;
    }

    &_bottom {
        align-items: flex-end;
    }

    .close-button {
        stroke: var(--main-default-button-color);
    }

    &__body {
        position: relative;
        background-color: var(--main-card-bg-color);
        border-radius: 15px;
        max-width: 500px;
        animation: slideInTop .6s ease;
        cursor: default;

        &-container_scroll {
            box-sizing: border-box;
            overflow-y: auto;
            max-height: 80vh;
        }

        .cross {
            position: absolute;
            right: -50px;
            top: 0;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;

            &::before, &::after {
                content: '';
                position: absolute;
                height: 4px;
                width: 28px;
                top: 50%;
                left: 50%;
                background: var(--main-font-secondary-color);
                border-radius: 5px;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        .chevron {
            display: none;
            position: absolute;
            top: 20px;
            right: 20px;
            background-color: var(--main-font-secondary-color);
            box-sizing: border-box;
            z-index: 5;
            padding: 0;
            border-radius: 50%;
            overflow: hidden;

            &__icon {
                height: 20px;
                width: 20px;
                padding: 5px;
                pointer-events: none;
            }
        }

        .swipe-block {
            position: absolute;
            inset: 0;
            z-index: 1;
            height: 40px;

            @media (min-width: 769px) {
                display: none;
            }

            &__line {
                position: absolute;
                top: 3px;
                left: 50%;
                right: 0;
                transform: translateX(-50%);
                width: 50%;
                height: 3px;
                background-color: var(--main-disabled-inner-color);
                border-radius: 2px 2px 0 0;
                pointer-events: none;
            }
        }
    }

    @media (max-width: 768px) {

        &__body {
            margin: 0;
            border-radius: var(--border-radius-only-top-small);
            overflow: hidden;

            &-container_scroll {
                max-height: 90vh;
                padding: 0;
            }

            .cross {
                display: none;
            }

            .chevron {
                display: flex;
            }
        }
    }
}
/* TODO: тех.долг: вынести в отдельный файл */  
/* QS-1243 */
@keyframes slideInTop {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
