.contacts {
  display: flex;
  flex-direction: column;
  grid-row-gap: 50px;

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin: 30px 0 0;
  }

  .scroll-container {
    display: flex;
    grid-column-gap: 20px;
    overflow-x: auto;

    .fake-card {
      height: 125px;
      width: 305px;
    }
  }

  &__address-card {
    min-width: 256px;
    height: 125px;
    max-width: 305px;
    width: 100%;
    background-color: var(--card-bg-color);
    border-radius: var(--border-radius-small);
    cursor: pointer;
    box-sizing: border-box;

    &-top {
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px;
      border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;

      img {
        height: 100%;
        box-sizing: border-box;
        padding: 12px;
      }
    }

    &-description {
      padding: 0 13px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: calc(100% - 20px);
    }

    &-city, &-address {
        margin: 0;
    }

    &-bottom {
      display: flex;
      align-items: center;
      grid-column-gap: 5px;
      margin-top: 15px;
    }

    &-time {
      color: var(--contacts-card-sub-font-color);
      margin: 0;
    }
  }

  &__map {
    width: 100%;
    height: 500px;

    iframe {
      border-radius: var(--border-radius-small);
      border: none;
      height: 100%;
      width: 100%;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2px;

    &-img {
      justify-self: end;
      max-width: 580px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-card {
      justify-self: center;
      display: flex;
      flex-direction: column;
      grid-row-gap: 20px;
      max-width: 522px;
      height: fit-content;
      width: 100%;
      background-color: var(--contacts-card-bg-color);
      border-radius: var(--border-radius-small);
      padding: 50px 30px;
      box-sizing: border-box;

      &-title {
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 10px;
      }

      &-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--main-font-color);
        margin: 0;
      }

      &-value {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--main-font-color);
      }
    }
  }
}

@media (max-width: 991px) {
  .contacts__container {
    grid-template-columns: 1fr;

    &-img {
      display: none;
    }

    &-card {
      justify-self: center;
    }
  }
}

@media (max-width: 768px) {
  .contacts {

    &__address-card {
      height: 125px;
    }
  }
}

@media (max-width: 500px) {
  .contacts {
    &__address-card {
      min-width: 256px;
    }

    &__map {
      height: 228px;
    }
  }
}
